import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

const useQueryParamNavigate = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [sp] = useSearchParams()

    return (spMutator) => {
        spMutator(sp)
        const q = sp.toString()
        navigate(`${location.pathname}${q.length > 0 ? `?${q}` : ''}`)
    }
}

export {
    useQueryParamNavigate
}