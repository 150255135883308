import {Box, Button, Card, CardContent, CardMedia, Grid, Skeleton, Stack, Typography} from "@mui/material";
import {makeStyles} from '@mui/styles';
import {SEARCH_STORE_NAME, searchStateNames} from "../Home/redux/searchSlice";
import {useSelector} from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import MapIcon from '@mui/icons-material/Map';
import {useNavigate, useSearchParams} from "react-router-dom";

const useStyles = makeStyles({
        itemBoxContainer: {
            height: 360,
            borderRadius: 20,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            overflow: 'hidden',
            position: 'relative'
        },
        widgetBlock: {
            display: "flex",
            justifyContent: "space-around"
        },
        img: {
            maxWidth: "100%",
            maxHeight: "100%"
        }
    }
);


export const List = () => {
    const localStyles = useStyles();
    const navigate = useNavigate()
    const [sp] = useSearchParams()

    const {
        [searchStateNames.entity]: searchResults,
        [searchStateNames.actionCompleted]: searchComplete,
        [searchStateNames.loading]: searchLoading,
        [searchStateNames.error]: searchError
    } = useSelector(state => state[SEARCH_STORE_NAME]);

    return (
        <Grid container spacing={2}
              sx={{
                  px: 2,
                  justifyContent: "center"
              }}>
            {
                searchLoading && [...Array(3).keys()].map(() => (
                    <Grid key={Math.random().toString()} item xs={12} sm={6} md={4} pb={2}>
                        <Box sx={{height: 500, width: "100%"}}>
                            <Skeleton variant="rectangular" component="div" height={460}/>
                        </Box>
                </Grid>))
            }
            {searchResults?.HotelStays?.map(r => (
                <Grid key={Math.random().toString()} item xs={12} sm={6} md={4} pb={2}>
                    <Card sx={{width: "100%", height: 500}}>
                        <CardMedia
                            component="img"
                            height="300"
                            classes={{img: localStyles.img}}
                            image={r.BasicPropertyInfo.RelativePosition?.Transportations.Transportation[0].MultimediaDescriptions.MultimediaDescription[0].ImageItems.ImageItem[0].ImageFormat[0].URL}
                            alt="hotel image"
                        >
                        </CardMedia>
                        <CardContent sx={{width: "100%", height: 200}}>
                            <Box sx={{display: "flex", flexDirection: "column", height: "100%"}}>
                                <Box sx={{flexGrow: 1}}>
                                    <Typography gutterBottom variant="h6" component="p">
                                        {r.BasicPropertyInfo.HotelName}
                                    </Typography>
                                    <Stack direction="row" sx={{fontSize: "0.2rem", marginBottom: 1}}>
                                        <StarIcon sx={{color: "#eebc1d", fontSize: "1.25rem"}}/>
                                        <StarIcon sx={{color: "#eebc1d", fontSize: "1.25rem"}}/>
                                        <StarIcon sx={{color: "#eebc1d", fontSize: "1.25rem"}}/>
                                        <StarIcon sx={{color: "#eebc1d", fontSize: "1.25rem"}}/>
                                        <StarIcon sx={{color: "#eebc1d", fontSize: "1.25rem"}}/>
                                    </Stack>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <Typography variant="body2" color="text.secondary" component="span" noWrap>
                                            {r.BasicPropertyInfo.Address.AddressLine}
                                        </Typography>
                                        &nbsp;
                                        <MapIcon color="text.secondary"/>
                                    </Box>
                                </Box>
                                <Box sx={{justifyContent: "center", display: "flex", width: "100%"}}>
                                    <Button size="small" variant={"contained"} color="primary" sx={{width: "100%"}}
                                            onClick={() => {
                                                const q = sp.get("q")
                                                sessionStorage.setItem("txId", searchResults.TransactionIdentifier)
                                                navigate(`/hotels/${r.BasicPropertyInfo.HotelCode}${q && `?q=${q}`}`)
                                            }}>
                                        View rooms
                                    </Button>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
            {searchComplete && searchResults?.HotelStays == null && <Typography variant="h6">Nothing was found</Typography>}
        </Grid>
    )
}