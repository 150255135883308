import LoaderButton from "./LoaderButton";
import {Alert, AlertTitle, Stack} from "@mui/material";

export const ErrorButton = ({onClick, loading, error, children, ...props}) => {
  return (
      <Stack direction="column">
          <LoaderButton
              variant="contained"
              loading={loading}
              onClick={onClick}
              {...props}>
              {children}
          </LoaderButton>
          {error &&
              <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {error}
              </Alert>
          }
      </Stack>
  )
}