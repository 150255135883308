import './App.css';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import Amplify from 'aws-amplify';
import {Authenticator} from '@aws-amplify/ui-react';
import {useRef} from "react";
import {Router} from "./components/Router";
import '@aws-amplify/ui-react/styles.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#343d46'
        },
        secondary: {
            main: '#a7adba'
        }
    },
    typography: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: 14,
        button: {
            fontFamily: "'Open Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif",
            textTransform: "none",
            fontWeight: 500
        }
    }
})



Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        mandatorySignIn: true,
        authenticationFlowType: 'USER_SRP_AUTH',
    }
});

function App() {
    const rootRef = useRef(null);

    return (
        <Authenticator loginMechanisms={['email']}>
            {({signOut, user}) => (
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <div ref={rootRef}>
                        <Router/>
                    </div>
                </ThemeProvider>)}
        </Authenticator>
    );
}

export default App;
