import {Box, Divider, Stack} from "@mui/material";
import {RoomTitle} from "./RoomTitle";

export const MultiRoomView = ({rooms, titleVariant}) => {
    return (
        <Stack spacing={1} sx={{width: 200}} divider={<Divider/>}>
            {rooms.map((r, i) => (
                <Box key={i}>
                    <RoomTitle room={r} variant={titleVariant}/>
                </Box>
            ))}
        </Stack>
    )
}