import {
    Autocomplete,
    Box,
    FormControl,
    Grid,
    InputLabel,
    NativeSelect,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import DateAdapter from "@mui/lab/AdapterMoment"
import {DateRangePicker, LocalizationProvider} from "@mui/lab";
import {useEffect, useState} from "react";
import * as dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {
    getCities,
    getCitiesStateNames,
    getHotels,
    getHotelsStateNames,
    search,
    SEARCH_STORE_NAME,
    searchStateNames
} from "./redux/searchSlice";
import {List} from "../List/List";
import {useQueryParamNavigate} from "../../hooks/queryParamNavigate";
import {makeNumberOptions} from "./helpers";
import {RoomPicker} from "./RoomPicker";
import PageLoader from "../../components/PageLoader";
import ErrorView from "../../components/ErrorView";
import {ErrorButton} from "../../components/ErrorButton";

const Filters = ({candidates, setCandidates, rooms, setRooms, stars, setStars}) => {
    return (
        <>
            <Stack direction="column" gap={1} sx={{}}>
                <Stack direction={"row"}>
                    <FormControl sx={{m: 1, width: 120}}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Star rating
                        </InputLabel>
                        <NativeSelect
                            defaultValue={stars}
                            onChange={v => setStars(v.target.value)}
                            inputProps={{
                                name: 'age',
                                id: 'uncontrolled-native',
                            }}
                        >
                            {<>
                                {makeNumberOptions(5, "star")}
                            </>}
                        </NativeSelect>
                    </FormControl>
                    <FormControl sx={{m: 1, width: 120}}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native2">
                            Rooms
                        </InputLabel>
                        <NativeSelect
                            value={rooms}
                            onChange={v => setRooms(v.target.value)}
                            inputProps={{
                                name: 'age',
                                id: 'uncontrolled-native2',
                            }}
                        >
                            {<>
                                {makeNumberOptions(30)}
                            </>}
                        </NativeSelect>
                    </FormControl>
                </Stack>
                <RoomPicker rooms={rooms} candidates={candidates} setCandidates={setCandidates}/>
            </Stack>
        </>
    )
}

export const Home = () => {
    const dispatch = useDispatch()
    const qp = useQueryParamNavigate()
    const [sp] = useSearchParams()
    const [date, setDate] = useState([dayjs().toDate(), dayjs().add(1, 'day').toDate()])
    const [destination, setDestination] = useState(null)
    const [hotelCode, setHotelCode] = useState(null)
    const [rooms, setRooms] = useState(1)
    const [stars, setStars] = useState(5)
    const [candidates, setCandidates] = useState([])

    const {
        [searchStateNames.entity]: searchResults,
        [searchStateNames.loading]: searchLoading,
        [searchStateNames.actionCompleted]: searchLoaded,
        [searchStateNames.error]: searchError,
        [getCitiesStateNames.entity]: cities,
        [getCitiesStateNames.loading]: citiesLoading,
        [getCitiesStateNames.error]: citiesError,
        [getHotelsStateNames.entity]: hotels,
        [getHotelsStateNames.loading]: hotelsLoading,
        [getHotelsStateNames.error]: hotelsError,
    } = useSelector(state => state[SEARCH_STORE_NAME]);

    useEffect(() => {
        if (!cities && !citiesLoading && !citiesError) {
            dispatch(getCities())
        }
    }, [cities, citiesLoading, citiesError])

    useEffect(() => {
        if (!hotels && !hotelsLoading && !hotelsError) {
            dispatch(getHotels())
        }
    }, [hotels, hotelsLoading, hotelsError])

    useEffect(() => {
        const lastSearch = sp.get("q")
        if (lastSearch && !searchResults && !searchLoading && !searchError) {
            const query = window.atob(lastSearch)
            dispatch(search(JSON.parse(query)))
        }
    }, [dispatch, searchResults, searchLoading, searchError])


    const makeHotelOptions = (hotels) => {
        const options = [];
        const nativeOptions = []

        for(let h of hotels) {
            let skip = false
            for(let o of options){
                skip = o.id === h.Code;
                if(skip){
                    break
                }
            }
            if (!skip){
                options.push({name: h.Name, id: h.Code})
            }
        }

        return {options, nativeOptions};
    }

    const makeOptions = (cities) => {
        const options = [];
        const nativeOptions = []

        cities.forEach(c => {
            options.push({name: `${c.Name}, ${c.Country}`, id: c.Code})
        })

        return {options, nativeOptions};
    }

    const makeRoomStayCandidates = (candidates) => {
        const ret = []

        const makeGuestCounts = (candidate) => {
            const ret = []
            Array(parseInt(candidate.adults)).fill(0).forEach(() => {
                ret.push({
                    "AgeQualifyingCode": "10",
                    "Age": 31,
                })
            })
            Array(parseInt(candidate.children)).fill(0).forEach(() => {
                ret.push({
                    "AgeQualifyingCode": "8",
                    "Age": 8,

                })
            })
            sessionStorage.setItem('guests', JSON.stringify(candidates))
            return ret;
        }

        candidates.forEach(c => {
            const guestCounts = makeGuestCounts(c)
            console.log(guestCounts.length)
            ret.push({
                "Quantity": 1,
                "GuestCounts": {
                    "GuestCount": [...guestCounts]
                }
            })
        })

        return ret
    }

    const filterHotels = (city, hotels) => {
        const ret = []
        hotels.forEach(h => {
            if (h.City === city) {
                ret.push(h)
            }
        })
        console.log("filtering hotels for", city, ret)
        return ret
    }


    const doSearch = (d0, d1, stars, rooms, candidates) => {
        let _candidates = []
        for (let i = 0; i < rooms; i++) {
            _candidates.push(candidates[i])
        }

        let req = {
            "MaxResponses": 3,
            "Currency": "GBP",
            "ExactMatchOnly": rooms === 1,
            "AvailRequestSegment": {
                "StayDateRange": {
                    "Start": dayjs(d0).format('YYYY-MM-DDTHH:mm:ss'),
                    "Duration": dayjs(d1).diff(d0, 'day').toString()
                },
                "HotelSearchCriteria": {
                    "Criterion": [
                        {
                            "HotelRef": {
                                "HotelCityCode": destination,
                                ...(hotelCode && {"HotelCode": hotelCode})
                            }
                        },
                        {
                            "Award": {
                                "Rating": stars.toString()
                            }
                        }
                    ]
                },
                "RoomStayCandidates": {
                    "RoomStayCandidate": makeRoomStayCandidates(_candidates)
                }
            }
        }
        qp(sp => sp.set("q", window.btoa(JSON.stringify(req))))
        dispatch(search(req))
    }

    return (
        <>
            {citiesError && <ErrorView/>}
            {
                cities ? <Grid container spacing={4} sx={{
                    paddingTop: 2,
                    justifyContent: "center"
                }}>
                    <Grid item xs={5}
                          sx={{
                              textAlign: "center",
                              justifyContent: "center"
                          }}>
                        <Stack direction="column" gap={3}>
                            <Autocomplete
                                id="search"
                                aria-label="search field"
                                getOptionLabel={o => o.name}
                                onChange={(e, o) => {
                                    setDestination(o.id)
                                }}
                                options={makeOptions(cities.Cities).options}
                                isOptionEqualToValue={(option, value) => {
                                    return option.id === value.id
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Where to?"
                                        InputProps={{
                                            ...params.InputProps,
                                        }}
                                    />
                                )}
                            />
                            {
                                hotels && destination && <Autocomplete
                                    id="search"
                                    aria-label="search field"
                                    getOptionLabel={o => o.name}
                                    onChange={(e, o) => {
                                        setHotelCode(o?.id)
                                    }}
                                    onInputChange={(e, v, reason) => {
                                        if (reason === "clear") {
                                            setHotelCode(null)
                                        }
                                    }}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.id === value.id
                                    }}
                                    options={makeHotelOptions(filterHotels(destination, hotels.Hotels)).options}
                                    filterOptions={(options, state) => {
                                        let ret = []
                                        options.forEach(v => {
                                            if(v.name.toLowerCase().includes(state.inputValue.toLowerCase())){
                                                ret.push(v)
                                            }
                                        })
                                        return ret
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Choose a hotel"
                                            InputProps={{
                                                ...params.InputProps,
                                            }}
                                        />
                                    )}
                                />
                            }
                            <LocalizationProvider dateAdapter={DateAdapter}>
                                <DateRangePicker
                                    startText="Check-in"
                                    endText="Check-out"
                                    value={date}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(newValue) => {
                                        setDate(newValue);
                                    }}
                                    renderInput={(startProps, endProps) => (
                                        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%"}}>
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%"
                                            }}>
                                                <TextField {...startProps} />
                                                <Box sx={{mx: 2}}> to </Box>
                                                <TextField {...endProps} />
                                            </Box>
                                            {dayjs(date[1]).diff(date[0], 'day') > 0
                                                &&
                                                <Typography sx={{px: 1, mt: 2, fontSize: "0.84rem"}}>
                                                    {dayjs(date[1]).diff(date[0], 'day').toString()}
                                                    {' '}
                                                    night{dayjs(date[1]).diff(date[0], 'day') > 1 && 's'}
                                                </Typography>
                                            }
                                        </Box>
                                    )}
                                />
                            </LocalizationProvider>
                            <Filters rooms={rooms}
                                     setRooms={setRooms}
                                     stars={stars}
                                     setStars={setStars}
                                     candidates={candidates}
                                     setCandidates={setCandidates}
                            />
                            <ErrorButton
                                variant="contained"
                                loading={searchLoading}
                                error={searchError}
                                onClick={() => doSearch(date[0], date[1], stars, rooms, candidates)}>
                                Search
                            </ErrorButton>
                        </Stack>
                    </Grid>
                    {
                        (searchLoaded || searchLoading) &&
                        <Grid item xs={12}>
                            <List/>
                        </Grid>
                    }
                </Grid> : <PageLoader/>
            }
        </>
    )
}