import {API_URL, asyncSliceActions, client} from "../../../redux/ez";
import {createSlice} from "@reduxjs/toolkit";

const SEARCH_STORE_NAME = 'SEARCH_STORE_NAME'

const {
    initialState: getCitiesInitialState,
    thunk: getCities,
    reducers: getCitiesReducers,
    stateNames: getCitiesStateNames
} = asyncSliceActions({
    actionName: "getCities",
    storeName: SEARCH_STORE_NAME,
    entityNameInStore: "cities",
    thunkName: "getCities",
    thunkAction: async () => {
        const res = await client.get(`/data/cities`);
        return res.data;
    }
})

const {
    initialState: getHotelsInitialState,
    thunk: getHotels,
    reducers: getHotelsReducers,
    stateNames: getHotelsStateNames
} = asyncSliceActions({
    actionName: "getHotels",
    storeName: SEARCH_STORE_NAME,
    entityNameInStore: "hotels",
    thunkName: "getHotels",
    thunkAction: async () => {
        const res = await client.get(`/data/hotels`);
        return res.data;
    }
})


const {
    initialState: searchInitialState,
    thunk: search,
    reducers: searchReducers,
    stateNames: searchStateNames
} = asyncSliceActions({
    actionName: "search",
    storeName: SEARCH_STORE_NAME,
    entityNameInStore: "search",
    thunkName: "search",
    thunkAction: async (req) => {
        console.log(API_URL)
        const res = await client.post(`/booking/hotels`, req);
        return res.data;
    }
})

const initialState = {
    ...searchInitialState,
    ...getCitiesInitialState,
    ...getHotelsInitialState
}

const searchSlice = createSlice(
    {
        name: 'auth',
        initialState,
        reducers: {
            resetState: (state, action) => {
                if (action.payload && action.payload.resetFunction) {
                    return action.payload.resetFunction(state);
                } else {
                    return initialState
                }
            }
        },
        extraReducers: {
            ...searchReducers,
            ...getCitiesReducers,
            ...getHotelsReducers
        }
    }
);

const searchReducer = searchSlice.reducer;
const resetState = searchSlice.actions.resetState;

export {
    SEARCH_STORE_NAME,
    searchReducer,

    search,
    resetState,
    searchStateNames,

    getCities,
    getCitiesStateNames,

    getHotels,
    getHotelsStateNames
}