import {combineReducers} from "redux";
import {notifierReducer} from "@aucsys/redux-ez";
import {SEARCH_STORE_NAME, searchReducer} from "../pages/Home/redux/searchSlice";
import {BOOKING_STORE_NAME, bookingReducer} from "../pages/Booking/redux/bookingSlice";

const reducers = {
    notifications: notifierReducer,
    [SEARCH_STORE_NAME]: searchReducer,
    [BOOKING_STORE_NAME]: bookingReducer
}

const rootReducer = combineReducers(reducers);

export default rootReducer;
