import {ReduxEz} from "@aucsys/redux-ez";
import {Auth} from "@aws-amplify/auth";

const API_URL = process.env.REACT_APP_API_URL

export const getAuthToken = async () => {
    try {
        const user = await Auth.currentSession();

        if (user) {
            return user.getIdToken().getJwtToken();
        }
    } catch (e) {}
    return 'eyJraWQiOiJEOGFqMDJha1dodG96RnRXN3JwSkZ5SWpkdWYxblBYS2hTam14WkxrWWc4PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJmMTM5MDY3Yi1jY2VkLTRmNDYtODAzOS1mNTBiZDQ3NTJjNDciLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfdmE5bFh2dEMzIiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjpmYWxzZSwiY29nbml0bzp1c2VybmFtZSI6ImYxMzkwNjdiLWNjZWQtNGY0Ni04MDM5LWY1MGJkNDc1MmM0NyIsIm9yaWdpbl9qdGkiOiIzNmFiM2I0Ny1lNjdhLTQ5ZWMtODA5Yy0xZjc1NTZlZDExMWMiLCJhdWQiOiI1aGVvaWUxaThwNmdsOXJpNWZucWhya2ViIiwiZXZlbnRfaWQiOiIxMTA1MWRhZS1mNTlhLTRkMzAtOWEzNS0zMTZlNDFkYzc3NzciLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY0MzAyNDcwMiwibmFtZSI6IkRhbmllbCBEIiwiZXhwIjoxNjQzMTIzNTk5LCJpYXQiOjE2NDMxMTk5OTksImp0aSI6ImFjZDJmYTJhLTU5YTktNDIxNC04ODFkLWE2ZGFiZjM5M2YwZiIsImVtYWlsIjoiZHJpbXl5QGdtYWlsLmNvbSJ9.uKUKZKZY-Mhyhufbzh29sKLzAIKGXOgZah5ocekLmbwjX_d7lwMkc61CuAAjVKaU4fblCF7qlrOgK9qc2sJjRzvHIC2JmIeXZo-ealuFdciqYcdVzpOyv5PX2Neu_Yh7EVv2Lj3EhMMgD23Zmvv293IbIWbG0bDuo1g0LAsXr2gHD_YpMUbqQKx8vofZaEDWdyzBdcrTUFVi6QA1lkNsb-EBq5V2EbZx_e_xbydMIpDrHiSwoBXPiSbBkETzQFCZBDrdOafV67RERNSjQUbru-ExZSlX4QEL6zokIFFmS7iRuzzjGkewtgjAAJzQsvElbDrdm-ZqSnokJVSpgANybg'
}

const {
    getSliceActions,
    createSliceActions,
    updateSliceActions,
    deleteSliceActions,
    createInListActions,
    updateInListActions,
    deleteInListActions,
    asyncSliceActions,
    client
} = ReduxEz({
    baseUrl: API_URL,
    makeHeaders: async () => {
        let headers = {}
        const token = await getAuthToken();

        if (token) {
            headers['Authorization'] = token;
        }
        return headers;
    }
})

export {
    getSliceActions,
    createSliceActions,
    updateSliceActions,
    deleteSliceActions,
    createInListActions,
    updateInListActions,
    deleteInListActions,
    asyncSliceActions,
    API_URL,
    client
}
