import {API_URL, asyncSliceActions, client} from "../../../redux/ez";
import {createSlice} from "@reduxjs/toolkit";

const BOOKING_STORE_NAME = 'BOOKING_STORE_NAME'

const {
    initialState: bookingInitialState,
    thunk: booking,
    reducers: bookingReducers,
    stateNames: bookingStateNames
} = asyncSliceActions({
    actionName: "booking",
    storeName: BOOKING_STORE_NAME,
    entityNameInStore: "booking",
    thunkName: "booking",
    thunkAction: async (req) => {
        const res = await client.post(`/booking/hotels/reservations`, req);
        return res.data;
    }
})

const {
    initialState: confirmBookingInitialState,
    thunk: confirmBooking,
    reducers: confirmBookingReducers,
    stateNames: confirmBookingStateNames
} = asyncSliceActions({
    actionName: "confirmBooking",
    storeName: BOOKING_STORE_NAME,
    entityNameInStore: "confirmBooking",
    thunkName: "confirmBooking",
    thunkAction: async (req) => {
        const res = await client.post(`/booking/confirm`, req);
        return res.data;
    }
})

const initialState = {
    ...bookingInitialState,
    ...confirmBookingInitialState
}

const bookingSlice = createSlice(
    {
        name: 'auth',
        initialState,
        reducers: {
            resetState: (state, action) => {
                if (action.payload && action.payload.resetFunction) {
                    return action.payload.resetFunction(state);
                } else {
                    return initialState
                }
            }
        },
        extraReducers: {
            ...bookingReducers,
            ...confirmBookingReducers
        }
    }
);

const bookingReducer = bookingSlice.reducer;
const resetState = bookingSlice.actions.resetState;

export {
    BOOKING_STORE_NAME,
    bookingReducer,
    booking,
    resetState,
    bookingStateNames,

    confirmBooking,
    confirmBookingStateNames,
}