import {useNavigate} from "react-router-dom";
import {GuestDetailsTile} from "./GuestDetailsTile";
import {Box, Divider, Grid, Stack} from "@mui/material";
import * as dayjs from "dayjs";
import {isFormValid} from "mui-ez";
import {booking, BOOKING_STORE_NAME, bookingStateNames} from "./redux/bookingSlice";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ErrorButton} from "../../components/ErrorButton";
import {unwrapResult} from "@reduxjs/toolkit";

export const Booking = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [validate, setValidate] = useState(false)
    const [rooms, setRooms] = useState([])
    const [leadPaxSelected, setLeadPaxSelected] = useState(false)
    const reservation = JSON.parse(sessionStorage.getItem('reservation'))
    const roomGuests = JSON.parse(sessionStorage.getItem('guests'))
    var validationRes = {}

    const {
        [bookingStateNames.entity]: bookingResult,
        [bookingStateNames.loading]: bookingLoading,
        [bookingStateNames.actionCompleted]: bookingLoaded,
        [bookingStateNames.error]: bookingError
    } = useSelector(state => state[BOOKING_STORE_NAME]);


    useEffect(() => {
        if (bookingResult && !bookingLoading) {
            sessionStorage.setItem('correlationId', bookingResult.CorrelationID)
            sessionStorage.setItem('reservation',
                JSON.stringify({
                    ...reservation,
                    guests: makeGuests(rooms)
                }))
            navigate("/confirm")
        }
    }, [bookingResult, bookingLoading])

    const makeGuests = (rooms) => {
        var ret = []
        var guests = roomsToGuests(rooms)
        guests.forEach((guest, i) => {
            const guestBd = guest.birthDate
            ret.push({
                "Age": dayjs(dayjs()).diff(guestBd, 'year'),
                "AgeQualifyingCode": (guest.type === 'Adult' && "10") || "8",
                "ResGuestRPH": (ret.length + 1).toString(),
                "ArrivalTime": {},
                "DepartureTime": {},
                "Profiles": {
                    "ProfileInfo": [{
                        "Profile": {
                            "Customer": {
                                "BirthDate": dayjs(guestBd).format('YYYY-MM-DD'),
                                "Email": {"Value": guest.email},
                                "PersonName": {
                                    "GivenName": guest.givenName,
                                    "NamePrefix": guest.namePrefix,
                                    "Surname": guest.surname
                                },
                                "Telephone": {"PhoneNumber": guest.phone}
                            }
                        },
                        ...(guest.leadPax && {"UniqueID": {"ID": "", "Type": "23"}})
                    }]
                },
            })
        })
        return ret
    }

    const roomsToGuests = (rooms) => {
        var guests = []
        rooms.forEach(r => r.forEach(g => guests.push(g)))
        return guests
    }

    const makeRequest = (rooms, txId) => {
        setValidate(true)
        if (isFormValid(validationRes) && leadPaxSelected) {
            const req = {
                "Action": "Hold",
                "TransactionIdentifier": txId,
                "HotelReservations": {
                    "HotelReservation": [{
                        "ResGuests": {
                            "ResGuest": makeGuests(rooms)
                        }
                    }],
                    "TPA_Extensions": {"AdditionalResultData": {"ResultIndex": reservation.hotel.rate.ratePlan.RatePlanID}}
                },
            }
            console.log(req)
            dispatch(booking(req)).then(unwrapResult).then(() => validationRes = {})
        }
    }

    useEffect(() => {
        if (rooms.length === 0) {
            roomGuests.map(g => {
                var adults = Array(parseInt(g.adults)).fill(0).map(() => ({id: Math.random(), type: 'Adult'}))
                var children = Array(parseInt(g.children)).fill(0).map(() => ({id: Math.random(), type: 'Child'}))
                setRooms(r => [...r, [...adults, ...children]])
            })
        }
    }, [])

    const RoomGuestListV2 = ({rooms, reservation}) => {
        return rooms.map((r, i) => (
            <div id={i}>
                <Divider key={Math.random()} sx={{my: 3}}>Room {i + 1}</Divider>
                <Stack key={Math.random()} spacing={3}>
                    {r.map((g, ii) => (<GuestDetailsTile validationRes={validationRes}
                                                         currentState={reservation}
                                                         validate={validate}
                                                         guest={g}
                                                         label={`Guest ${ii + 1} (${g.type})`}
                                                         setLeadPaxSelected={setLeadPaxSelected}
                                                         leadPaxSelected={leadPaxSelected}
                                                         id={ii+i}
                                                         key={ii+i}
                                                         onChange={(g) => {
                                                             setRooms(rr => {
                                                                 rr[i][ii] = g
                                                                 return rr
                                                             })
                                                         }}/>))}
                </Stack>
            </div>
        ))
    }

    return (
        <>
            {reservation && <Grid container spacing={2} sx={{
                justifyContent: "center",
                direction: "column"
            }}>
                <Grid item xs={12} sm={8} md={6} lg={5}
                      sx={{
                          justifyContent: "center"
                      }}>
                    <RoomGuestListV2 rooms={rooms} reservation={reservation}/>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{display: "flex", justifyContent: "center", width: "100%"}}>
                        <ErrorButton
                            variant="contained"
                            sx={{width: 200}}
                            loading={bookingLoading}
                            error={bookingError}
                            onClick={() => makeRequest(
                                rooms,
                                sessionStorage.getItem("txId"))}>
                            Continue
                        </ErrorButton>
                    </Box>
                </Grid>
            </Grid>
            }
        </>
    )
}