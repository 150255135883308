import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    Paper,
    Radio,
    Typography
} from "@mui/material";
import {useState} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useNavigate} from "react-router-dom";
import {RoomsView} from "../../components/RoomsView";
import Price from "../../components/Price";

const img = (
    <Box sx={{display: 'flex', justifyContent: "center", alignItems: "center", height: "100%", mr: 3}}>
        <img style={{height: 96, width: 96}}
             src={''}
             alt="omg"/>
    </Box>
)

const RoomRates = (rates) => {
    const [selectedRate, setSelectedRate] = useState(0)
    return (
        <>
            {rates.RoomRate.map(rr => (
                <Grid item xs={12}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <Typography>
                            {rr.RoomRateDescription.Name}
                        </Typography>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <Price currency={rr.Total.CurrencyCode} total={rr.Total.AmountAfterTax}/>
                            <Radio
                                checked={false}
                                onChange={() => {
                                }}
                                value="a"
                                name="radio-buttons"
                                inputProps={{'aria-label': 'A'}}
                            />
                        </Box>
                    </Box>
                </Grid>
            ))}
        </>
    )
}

export const Room = ({room, onClickBook}) => {
    const navigate = useNavigate()
    const [selectedRate, setSelectedRate] = useState(0)

    const onClickReserve = (room, rate) => {
        console.log(rate)
        onClickBook({room, rate})
    }

    return (
        <Grid item xs={12}>
            <Paper variant="outlined" style={{width: "100%"}}>
                <Grid container px={2} py={1}>
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', flexDirection: "row", justifyContent: "space-between"}}>
                            <RoomsView titleVariant="h6" rooms={room.RoomTypes.RoomType}/>
                            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <Price variant="h5" gutterBottom currency={room.RoomRates.RoomRate[selectedRate].Total.CurrencyCode} total={room.RoomRates.RoomRate[selectedRate].Total.AmountAfterTax}/>
                                <Button variant="contained"
                                        onClick={() => onClickReserve(room.RoomTypes.RoomType, {
                                            ratePlan: room.RatePlans.RatePlan[selectedRate],
                                            roomRate: room.RoomRates.RoomRate[selectedRate]
                                        })}>
                                    Reserve now
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{width: "100%"}}>
                    <Accordion onChange={() => {
                    }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                        >
                            <Typography sx={{width: '33%', flexGrow: 1}}>
                                Board type
                            </Typography>
                            <Typography sx={{color: 'text.secondary'}}>
                                {room.RoomRates.RoomRate[selectedRate].RoomRateDescription.Name}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                {room.RoomRates.RoomRate.map((rr, i) => (
                                    <Grid item xs={12} key={i}>
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}>
                                            <Typography>
                                                {rr.RoomRateDescription.Name}
                                            </Typography>
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}>
                                                <Price currency={rr.Total.CurrencyCode} total={rr.Total.AmountAfterTax}/>
                                                <Radio
                                                    checked={i == selectedRate}
                                                    onChange={(e) => {
                                                        console.log('val', typeof e.target.value)
                                                        setSelectedRate(e.target.value)
                                                    }}
                                                    value={i}
                                                    name="radio-buttons"
                                                    inputProps={{'aria-label': 'A'}}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Paper>
        </Grid>
    )
}