import {Box, Grid, Skeleton, Stack, Typography} from "@mui/material";
import {search, SEARCH_STORE_NAME, searchStateNames} from "../Home/redux/searchSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Room} from "../List/Room";

export const Hotel = () => {
    let {id} = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [sp] = useSearchParams()
    const [hotel, setHotel] = useState(null)
    const [lastSearch, setLastSearch] = useState(null)

    const {
        [searchStateNames.entity]: searchResults,
        [searchStateNames.loading]: searchLoading,
        [searchStateNames.error]: searchError
    } = useSelector(state => state[SEARCH_STORE_NAME]);

    useEffect(() => {
        const lastSearch = sp.get("q")
        if (lastSearch && !searchResults && !searchLoading && !searchError) {
            let query
            try {
                query = window.atob(lastSearch)
            } catch (e) {
                navigate("/")
            }

            dispatch(search(JSON.parse(query)))
            setLastSearch(query)
        }

        if (searchError) {
            navigate("/")
        }
    }, [dispatch, searchResults, searchLoading, navigate, searchError, sp])

    useEffect(() => {
        const findHotel = (id, results) => {
            for (let r of results) {
                if (r.BasicPropertyInfo.HotelCode === id) {
                    return r
                }
            }
        }

        if (searchResults && id) {
            const h = findHotel(id, searchResults.HotelStays)
            setHotel(h)
        }
    }, [id, searchResults])


    const findRoom = (id, results) => {
        for (let r of results) {
            if (r.RPH === id) {
                return r
            }
        }
    }

    const onClickBook = ({room, rate}) => {
        sessionStorage.setItem('reservation', JSON.stringify({
            hotel: {
                name: hotel.BasicPropertyInfo.HotelName,
                room,
                rate
            }
        }))
        navigate("/book")
    }

    return (
        <>
            {hotel && <Grid container spacing={2} sx={{
                paddingTop: 2,
                justifyContent: "center"
            }}>
                <Grid item sm={12} md={7}
                      sx={{
                          justifyContent: "center"
                      }}>
                    <Box sx={{
                        backgroundImage: `url("${hotel.BasicPropertyInfo.RelativePosition?.Transportations.Transportation[0].MultimediaDescriptions.MultimediaDescription[0].ImageItems.ImageItem[0].ImageFormat[0].URL}")`,
                        backgroundSize: "cover",
                        width: "100%",
                        height: 500
                    }}/>
                </Grid>
                <Grid item sm={12} md={7}>
                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <Typography variant="h5">
                            {hotel.BasicPropertyInfo.HotelName}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item container spacing={1} sm={12} md={7}>
                    {hotel.RoomStayRPH.split(" ").map(id => (
                        <Room key={id} onClickBook={onClickBook} room={findRoom(id, searchResults.RoomStays)}/>))}
                </Grid>
            </Grid>}
            {searchLoading &&
                <Grid container spacing={2} sx={{
                    paddingTop: 2,
                    justifyContent: "center"
                }}>
                    <Grid item sm={12} md={7}
                          sx={{
                              justifyContent: "center"
                          }}>
                        <Stack spacing={2}>
                            <Skeleton variant="rectangular" height={500}/>
                            <Skeleton variant="text" height={36}/>
                            <Skeleton variant="rectangular" height={105}/>
                        </Stack>
                    </Grid>
                </Grid>
            }
        </>
    )
}