import React from "react";
import {Box, Typography} from "@mui/material";

export default function ErrorView() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                height: "100%"
            }}
        >
            <Typography variant="h4" component="p" gutterBottom>Something went wrong</Typography>
            <Typography variant="h6" component="p">Please try to reload the page</Typography>
        </Box>
    )
}
