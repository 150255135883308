import {Box, Grid, Paper, Stack, Typography} from "@mui/material";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BOOKING_STORE_NAME, confirmBooking, confirmBookingStateNames} from "../redux/bookingSlice";
import {RoomsView} from "../../../components/RoomsView";
import {ErrorButton} from "../../../components/ErrorButton";
import Price from "../../../components/Price";
import {emptyTextValidator, isFormValid, ValidatingTextInput} from "mui-ez";
import markup from "../../../kamMarkup";

export const Confirm = () => {
    const dispatch = useDispatch()
    const correlationId = useState(sessionStorage.getItem('correlationId') || null)
    const [reservationState] = useState(JSON.parse(sessionStorage.getItem('reservation')) || null)
    const [externalId, setExternalId] = useState('')
    const [validate, setValidate] = useState(false)
    var validationRes = {}

    const {
        [confirmBookingStateNames.loading]: bookingLoading,
        [confirmBookingStateNames.actionCompleted]: bookingLoaded,
        [confirmBookingStateNames.error]: bookingError
    } = useSelector(state => state[BOOKING_STORE_NAME]);

    const makeRequest = (correlationId, externalId, price) => {
        setValidate(true)
        if (isFormValid(validationRes)) {
            const req = {
                "CorrelationID": correlationId,
                "ExternalBookingID": externalId,
                "Price": markup(price)
            }
            dispatch(confirmBooking(req))
        }
    }

    const GuestTile = ({guest}) => {
        return (
            <Grid item xs={12}>
                <Paper variant="outlined" sx={{p: 2}}>
                    <Box>
                        <Typography variant="h6"
                                    sx={{fontWeight: "bold"}}>{guest.Profiles.ProfileInfo[0].Profile.Customer.PersonName.NamePrefix}. {guest.Profiles.ProfileInfo[0].Profile.Customer.PersonName.GivenName} {guest.Profiles.ProfileInfo[0].Profile.Customer.PersonName.Surname}</Typography>
                        <Typography>{guest.Profiles.ProfileInfo[0].Profile.Customer.Email.Value}</Typography>
                        <Typography>{guest.Profiles.ProfileInfo[0].Profile.Customer.Telephone.PhoneNumber}</Typography>
                    </Box>
                </Paper>
            </Grid>
        )
    }

    return (
        <>
            {
                reservationState &&
                <Grid container spacing={2} sx={{
                    paddingTop: 2,
                    justifyContent: "center"
                }}>
                    <Grid item container xs={12} sm={8} md={6} lg={5}
                          spacing={3}
                          sx={{
                              justifyContent: "center"
                          }}>
                        {reservationState?.guests.map(guest => (
                            <GuestTile key={Math.random()} guest={guest}/>
                        ))}
                        <Grid item xs={12}>
                            <Paper variant="outlined" sx={{p: 2}}>
                                <Box>
                                    <Stack spacing={1}>
                                        <Typography variant="h6"
                                                    sx={{fontWeight: "bold"}}>{reservationState?.hotel?.name}</Typography>
                                        <RoomsView rooms={reservationState.hotel.room}/>
                                        <Typography>{reservationState?.hotel.rate.roomRate.RoomRateDescription.Name}</Typography>
                                        <Price sx={{fontWeight: "bold"}} currency={reservationState?.hotel?.rate.roomRate.Total.CurrencyCode} total={reservationState?.hotel?.rate.roomRate.Total.AmountAfterTax}/>
                                    </Stack>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <ValidatingTextInput
                                variant="outlined"
                                label={"External reference ID"}
                                value={externalId}
                                setter={setExternalId}
                                id={"extRef"}
                                validationRes={validationRes}
                                validator={emptyTextValidator}
                                validate={validate}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: "flex", justifyContent: "center"}}>
                                <ErrorButton
                                    sx={{width: 200}}
                                    variant={"contained"}
                                    loading={bookingLoading}
                                    error={bookingError}
                                    disabled={bookingLoaded}
                                    onClick={() => makeRequest(sessionStorage.getItem('correlationId'), externalId, reservationState?.hotel?.rate.roomRate.Total.AmountAfterTax)}>
                                    {bookingLoaded ? `Reservation Confirmed` : `Confirm reservation`}
                                </ErrorButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
}