import {AppBar, Link, Toolbar} from "@mui/material";

export const Header = () => {

    return (
        <AppBar position="static" sx={{marginBottom: 2}}>
            <Toolbar>
                <Link variant="h2" color={"#fff"} underline="none" href={"/"}>
                    DubaiPriceBeater.com
                </Link>
            </Toolbar>

        </AppBar>
    )
}