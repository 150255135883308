import {TextField} from "@mui/material";
import {DatePicker} from "@mui/lab";
import React from "react";

const ValidatingDatePicker = ({label, id, value, setValue, validate, validationRes}) => {

    let isShowValidationError = false;
    if (validationRes && id) {
        const isInputValid = value !== null && value !== undefined && value !== '';
        validationRes[id] = isInputValid;
        if (validate) {
            isShowValidationError = !isInputValid;
        } else {
            isShowValidationError = false;
        }
    }

    return (
        <DatePicker
            label={label}
            value={value}
            inputFormat="DD/MM/YYYY"
            onChange={(v) => {
                setValue(v);
            }}
            renderInput={(params) => <TextField
                {...params}
                fullWidth
                margin="normal"
                error={isShowValidationError}
                helperText={isShowValidationError ? "Can't be empty" : null}
                InputProps={{
                    ...params.InputProps,
                }}
            />}
        />
    )
}

export default ValidatingDatePicker;