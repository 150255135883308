import {RoomTitle} from "./RoomTitle";
import {MultiRoomView} from "./MultiRoomView";

export const RoomsView = ({rooms, titleVariant}) => {
    return (
        <>
            {rooms.length === 1 && <RoomTitle room={rooms[0]} variant={titleVariant}/>}
            {rooms.length > 1 && <MultiRoomView rooms={rooms} titleVariant={titleVariant}/> }
        </>
    )
}