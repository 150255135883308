import {BrowserRouter, Route, Routes} from "react-router-dom";
import {MainWrapper} from "../pages/MainWrapper";
import {Home} from "../pages/Home/Home";
import {Hotel} from "../pages/Hotel/Hotel";
import {Booking} from "../pages/Booking/Booking";
import {Confirm} from "../pages/Booking/Confirm/Confirm";

export const Router = () => {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<MainWrapper/>}>
                  <Route path="/" element={<Home/>}/>
                  <Route path="/hotels/:id" element={<Hotel/>}/>
                  <Route exact path="/book" element={<Booking/>}/>
                  <Route exact path="/confirm" element={<Confirm/>}/>
              </Route>
          </Routes>
      </BrowserRouter>
  )
}