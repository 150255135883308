import {Typography} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';

export const RoomTitle = ({room, variant}) => {
    const makeOccupancy = (room) => {
        if (room.Occupancy) {
            switch (room.Occupancy) {
                case "Single":
                    return 1
                case "Double":
                    return 2
                case "Triple":
                    return 3
            }
        }

        if (room.GuestCounts.GuestCount) {
            let total = 0
            room.GuestCounts.GuestCount.forEach(gc => {
                total += gc.Count
            })
            return total
        }
        return 0
    }
    return (
        <Typography gutterBottom {...(variant && {variant})}>
            {room.RoomType}
            {
                room.Quantity &&
                room.Quantity > 1 &&
                <>
                    &nbsp;
                    <Typography component="span">
                        x{room.Quantity}
                    </Typography>
                    &nbsp;
                </>
            }
            <Typography component="p">
                {[...Array(makeOccupancy(room["TPA_Extensions"].TPARoomDetail)).keys()].map((_, i) => (<PersonIcon key={i} fontSize="small"/>))}
            </Typography>
        </Typography>
    )
}