import {Typography} from "@mui/material";
import markup from "../kamMarkup";

const Price = ({currency, total, ...props}) => {
    const c = () => {
        switch (currency) {
            case "GBP":
                return "£"
            case "EUR":
                return "€"
            case "USD":
                return "$"
            default:
                return currency
        }
    }
    return (
        <Typography {...props}>
            {c() + markup(total)}
        </Typography>
    )
}

export default Price