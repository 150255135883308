import {Divider, FormControl, InputLabel, NativeSelect, Stack} from "@mui/material";
import {makeNumberOptions} from "./helpers";
import {useEffect, useState} from "react";

const Room = ({i, setCandidates}) => {
    const [adults, setAdults] = useState(1)
    const [children, setChildren] = useState(0)

    useEffect(() => {
        setCandidates(c => {
            c[i] = {adults, children}
            return c
        })
    }, [adults, children, setCandidates, i])

    return (
        <>
            <Divider>Room {i + 1}</Divider>
            <Stack>
                <Stack direction="row">
                    <FormControl sx={{m: 1, width: 120}}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Adults
                        </InputLabel>
                        <NativeSelect
                            value={adults}
                            inputProps={{
                                name: 'age',
                                id: 'uncontrolled-native',
                            }}
                            onChange={v => setAdults(v.target.value)}
                        >
                            {<>
                                {makeNumberOptions(30)}
                            </>}
                        </NativeSelect>
                    </FormControl>
                    <FormControl sx={{m: 1, width: 120}}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native2">
                            Children
                        </InputLabel>
                        <NativeSelect
                            value={children}
                            onChange={v => setChildren(v.target.value)}
                            inputProps={{
                                name: 'age',
                                id: 'uncontrolled-native2',
                            }}
                        >
                            {<>
                                {makeNumberOptions(10, null, {fromZero: true})}
                            </>}
                        </NativeSelect>
                    </FormControl>
                </Stack>
            </Stack>
        </>
    )
}

export const RoomPicker = ({rooms, candidates, setCandidates}) => {

    return (
        <>
            {[...Array(parseInt(rooms)).fill(0)].map((_, i) => (
                <Room key={i} i={i} setCandidates={setCandidates}/>
            ))}
        </>
    )
}