import {Outlet} from "react-router-dom";
import {Header} from "../components/Header";

export const MainWrapper = () => {
  return (
      <main style={{overflowX: 'hidden', overflowY: 'hidden', minHeight: '100vh'}}>
          <Header/>
          <Outlet/>
      </main>
  )
}