import {
    Box,
    Checkbox,
    emphasize,
    FormControlLabel,
    getContrastRatio,
    getLuminance,
    Grid,
    IconButton,
    Paper,
    Stack,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import {useEffect, useState} from "react";
import {EmailField, emptyTextValidator, PhoneField, ValidatingTextInput} from "mui-ez";
import DateAdapter from "@mui/lab/AdapterMoment"
import {LocalizationProvider} from "@mui/lab";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SelectWithOneChoosableOption from "../../components/SelectWithOneChoosableOption";
import ValidatingDatePicker from "../../components/ValidatingDatePicker";


export const getBackgroundColor = (theme) => {
    if (theme.palette.background.default) {
        return theme.palette.background.default;
    } else {
        if (theme.palette.mode === 'dark') {
            return "#121212";
        } else {
            return "#fff";
        }
    }
}
export const getDialogBackgroundColor = (theme) => {
    if (getLuminance(getBackgroundColor(theme)) > 0.5) {
        return getBackgroundColor(theme);
    }

    if (getContrastRatio(emphasize(getBackgroundColor(theme), 0.2), getBackgroundColor(theme)) > 1.6) {
        return emphasize(getBackgroundColor(theme), 0.2)
    } else {
        return getBackgroundColor(theme);
    }
}

export const GuestDetailsTile = ({
                                     currentState,
                                     validate,
                                     validationRes,
                                     guest,
                                     onChange,
                                     label,
                                     setLeadPaxSelected,
                                     leadPaxSelected,
                                 }) => {
    const theme = useTheme();
    const [namePrefix, setNamePrefix] = useState(guest?.namePrefix || '')
    const [givenName, setGivenName] = useState(guest?.givenName || '')
    const [surname, setSurname] = useState(guest?.surname || '')
    const [birthDate, setBirthDate] = useState(guest?.birthDate || '')
    const [email, setEmail] = useState(guest?.email || '')
    const [phone, setPhone] = useState(guest?.phone || '')
    const [leadPax, setLeadPax] = useState(guest?.leadPax && true || false)
    const [guestType] = useState(guest?.type || '')
    const [id] = useState(guest?.id)

    useEffect(() => {
        if (namePrefix || givenName || surname || birthDate || email || phone || leadPax || guestType) {
            onChange({
                ...guest,
                namePrefix,
                givenName,
                surname,
                birthDate,
                email,
                phone,
                leadPax,
                type: guestType
            })
        }
    }, [namePrefix, givenName, surname, birthDate, email, phone, leadPax, guestType])

    useEffect(() => {
        if (leadPaxSelected === id) {
            setLeadPax(true)
        }
        if (leadPaxSelected !== id) {
            setLeadPax(false)
        }
    }, [leadPaxSelected, id])


    const Option = (s) => ({
        id: s,
        name: s
    })

    const options = (opts) => {
        return opts?.map(v => Option(v)) || []
    }

    return (
        <Paper sx={{p: 2}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%"
                    }}>
                        <Typography variant="h5">{label}</Typography>
                        {guest.type === 'Adult' &&
                            <Box sx={{display: "flex", flexDirection: "row"}}>
                                <Box>
                                    {(!leadPaxSelected || (leadPaxSelected === id)) &&
                                        <FormControlLabel control={<Checkbox checked={leadPax} onChange={(e) => {
                                            setLeadPax(e.target.checked)
                                            if (e.target.checked)
                                                setLeadPaxSelected(id)
                                            else setLeadPaxSelected(null)
                                        }}/>}
                                                          label="Lead guest"/>}
                                    {validate && !leadPaxSelected && <Typography color={"error"} variant="caption" paragraph={true}>Please choose one</Typography>}
                                </Box>
                                <Tooltip title={"This guest will be primary contact person for the group"} arrow>
                                    <IconButton
                                        size={"small"}
                                        color="primary"
                                        edge="start"
                                    >
                                        <HelpOutlineIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={2} direction="column">
                        <SelectWithOneChoosableOption
                            label={"Title"}
                            setter={setNamePrefix}
                            value={namePrefix}
                            options={options(["Ms",  "Mrs", "Mr", "Dr", "Sir"])}
                            id={"namePrefix"+id}
                            validationFailText={"Can't be empty"}
                            validate={validate}
                            validationRes={validationRes}
                        />
                        <ValidatingTextInput
                            variant="outlined"
                            label={"Name"}
                            value={givenName}
                            setter={setGivenName}
                            id={"givenName"+id}
                            validationRes={validationRes}
                            validator={emptyTextValidator}
                            validate={validate}/>
                        <ValidatingTextInput
                            variant="outlined"
                            label={"Last name"}
                            value={surname}
                            setter={setSurname}
                            id={"surname"+id}
                            validationRes={validationRes}
                            validator={emptyTextValidator}
                            validate={validate}/>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                            <ValidatingDatePicker
                                label="Birth date"
                                id={"birthday"+id}
                                value={birthDate}
                                setValue={setBirthDate}
                                validate={validate}
                                validationRes={validationRes}
                            />
                        </LocalizationProvider>
                        <EmailField value={email}
                                    setter={setEmail}
                                    validate={validate}
                                    id={"email"+id}
                                    validationRes={validationRes}/>
                        <PhoneField value={phone}
                                    setter={setPhone}
                                    validate={validate}
                                    validationRes={validationRes}
                                    id={"phone"+id}
                                    colorStyle={theme.palette.getContrastText(getDialogBackgroundColor(theme))}
                                    backgroundColor={getDialogBackgroundColor(theme)}
                                    inputProps={{
                                        style: {
                                            width: "100%",
                                            background: "unset"
                                        },
                                        autoComplete: "tel"
                                    }}/>
                    </Stack>
                </Grid>
            </Grid>
        </Paper>
    )
}